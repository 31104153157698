// colors
$color-primary: #4F86B7;
$color-primary-dark: #2D5170;
$color-black: #000;
$color-white: #fff;
$color-light: #F5F2E8;
$color-gray: #666666;

// base
$font-size-base: 17px;
$line-height-base: 1.4;
$font-family-base: "SF Pro Display", sans-serif;

// breakpoints
$small: 575px;
$mobile: 767px;