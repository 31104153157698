@import '../../assets/scss/variables.scss';
.invite {
    display: flex;
    margin: 0 auto;
    max-width: 767px;
    min-height: 100dvh;
    flex-direction: column;
    justify-content: space-between;
    article {
        height: max-content;
        display: flex;
        padding: 15px;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        h1 {
            font-size: 34px;
            font-weight: 700;
            line-height: 1.2;
            margin-bottom: 10px;
            color: $color-primary-dark;
        }
        p {
            margin-bottom: 20px;
        }
    }
    picture {
        overflow: hidden;
        flex: auto;
        position: relative;
        img {
            width: 100%;
            position: absolute;
        }
        &:nth-of-type(1) {
            img {
                bottom: 0;
            }
        }
        &:nth-of-type(2) {
            img {
                top: 0;
            }
        }
    }
    &__link {
        gap: 15px;
        display: flex;
        justify-content: center;
    }
}